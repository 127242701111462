import React from "react";

type ButtonLabel =
  | "A"
  | "B"
  | "X"
  | "Y"
  | "L1"
  | "R1"
  | "L2"
  | "R2"
  | "Select"
  | "Start"
  | "LS"
  | "RS"
  | "Up"
  | "Down"
  | "Left"
  | "Right";

const buttonMapping: ButtonLabel[] = [
  "A",
  "B",
  "X",
  "Y",
  "L1",
  "R1",
  "L2",
  "R2",
  "Select",
  "Start",
  "LS",
  "RS",
  "Up",
  "Down",
  "Left",
  "Right",
];

interface ButtonMapping {
  onA?: () => void;
  onB?: () => void;
  onX?: () => void;
  onY?: () => void;

  onUp?: () => void;
  onDown?: () => void;
  onLeft?: () => void;
  onRight?: () => void;

  onStart?: () => void;
  onSelect?: () => void;

  onL1?: () => void;
  onR1?: () => void;
  onL2?: () => void;
  onR2?: () => void;

  onLS?: () => void;
  onRS?: () => void;
}

export const useGamepad = (mapping: ButtonMapping) => {
  const [, setButtonStates] = React.useState<boolean[]>([]);

  const updateGamepad = () => {
    const gamepads = navigator.getGamepads();

    if (gamepads.length && gamepads.length > 0 && gamepads[0]) {
      const gamepad = gamepads[0];
      updateAllButtons(gamepad);
    }

    animationFrameRef.current = requestAnimationFrame(updateGamepad);
  };

  const updateAllButtons = (gamepad: Gamepad) => {
    const newButtonStates = gamepad.buttons.map((b) => b.pressed);
    setButtonStates((buttonStates) => {
      newButtonStates.forEach((newState: boolean, i: number) => {
        const oldState = i < buttonStates.length ? buttonStates[i] : false;
        if (newState && !oldState) {
          const callback = mapping[`on${buttonMapping[i]}`];
          if (callback) {
            callback();
          }
        }
      });
      return newButtonStates;
    });
  };

  const animationFrameRef = React.useRef(0);
  React.useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(updateGamepad);
    return () => cancelAnimationFrame(animationFrameRef.current);
  });
};
