import "../styles/buttons-pane.scss";
import ActionButtons from "./ActionButtons";
import NavButtons from "./NavButtons";

export interface ButtonSpec {
  buttonId: string;
  buttonText: string;
  onClick: () => void;
}

export interface NavButtonSpec extends ButtonSpec {
  displaying: boolean;
  highlighted: boolean;
  setSelected: () => void;
  onInitialClick: () => void;
}

interface Props {
  navButtons: NavButtonSpec[];
  actionButtons: ButtonSpec[];
  buttonsDisabled: boolean;
}

function ButtonsPane(props: Props) {
  return (
    <div className="buttons-pane">
      <NavButtons
        buttons={props.navButtons}
        buttonsDisabled={props.buttonsDisabled}
      />
      <ActionButtons buttons={props.actionButtons} />
    </div>
  );
}

export default ButtonsPane;
