import "../styles/particles.scss";
import { DrawFunction, useCanvas } from "../ts/canvas";

interface Props {
  draw: DrawFunction;
}

function Canvas(props: Props) {
  const canvasRef = useCanvas(props.draw);
  return <canvas ref={canvasRef} className="particles" />;
}

export default Canvas;
