import { useEffect, useRef } from "react";

export type DrawFunction = (
  context: CanvasRenderingContext2D,
  frameCount: number
) => void;

export const useCanvas = (draw: DrawFunction) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  function resizeCanvas(
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    width: number,
    height: number
  ) {
    if (canvas.width !== width || canvas.height !== height) {
      const { devicePixelRatio: ratio = 1 } = window;
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      context.scale(ratio, ratio);
      return true;
    }

    return false;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    let animationFrameId: number | null = null;
    if (canvas !== null) {
      const context = canvas.getContext("2d");
      if (context !== null) {
        let frameCount = 0;
        const render = () => {
          frameCount++;
          const { width, height } = canvas.getBoundingClientRect();
          resizeCanvas(canvas, context, width, height);
          context.clearRect(0, 0, width, height);
          draw(context, frameCount);
          animationFrameId = window.requestAnimationFrame(render);
        };
        render();
      }
    }

    return () => {
      if (animationFrameId !== null) {
        window.cancelAnimationFrame(animationFrameId);
      }
    };
  }, [draw]);

  return canvasRef;
};

export default useCanvas;
