import "../styles/nav-buttons.scss";
import { NavButtonSpec } from "./ButtonsPane";
import NavButton from "./NavButton";

interface Props {
  buttons: NavButtonSpec[];
  buttonsDisabled: boolean;
}

function NavButtons(props: Props) {
  return (
    <div className="window nav-buttons">
      {props.buttons.map((button, i) => (
        <NavButton
          key={i}
          buttonId={button.buttonId}
          buttonText={button.buttonText}
          isDisabled={props.buttonsDisabled}
          isSelected={button.highlighted}
          isDisplaying={button.displaying}
          onClick={button.onClick}
          onInitialClick={button.onInitialClick}
          setSelected={button.setSelected}
        />
      ))}
    </div>
  );
}

export default NavButtons;
