import React from "react";
import * as animations from "../ts/animations";
import { useGamepad } from "../ts/gamepad";
import { useLocalStorage } from "../ts/localstorage";
import { none, sakura, snow } from "../ts/weather";
import { ButtonSpec, NavButtonSpec } from "./ButtonsPane";
import Canvas from "./Canvas";
import Content from "./Content";
import { onClick } from "./NavButton";
import Squares, { ScrollDirection, Season } from "./Squares";

function getCurrentMonth() {
  // we care about seasons so we realign the months to make seasons simple
  // Dec = 0, Jan = 1, ... Nov = 11
  return (new Date().getMonth() + 1) % 12;
}

function seasonForMonth(month: number): Season {
  switch (month) {
    case 0:
    case 1:
    case 2:
      return Season.winter;
    case 3:
    case 4:
    case 5:
      return Season.spring;
    case 6:
    case 7:
    case 8:
      return Season.summer;
    case 9:
    case 10:
    case 11:
      return Season.autumn;
    default:
      return Season.spring;
  }
}

function nextScrollDirection(
  scrollDirection: ScrollDirection
): ScrollDirection {
  switch (scrollDirection) {
    case ScrollDirection.se:
      return ScrollDirection.sw;
    case ScrollDirection.sw:
      return ScrollDirection.nw;
    case ScrollDirection.nw:
      return ScrollDirection.ne;
    case ScrollDirection.ne:
      return ScrollDirection.se;
    default:
      return scrollDirection;
  }
}

function iconForSeason(season: Season) {
  switch (season) {
    case Season.winter:
      return "❄️";
    case Season.spring:
      return "🌸";
    case Season.summer:
      return "☀️";
    case Season.autumn:
      return "🍂";
    default:
      return "_";
  }
}

function iconForScrollDirection(scrollDirection: ScrollDirection) {
  switch (scrollDirection) {
    case ScrollDirection.se:
      return "↘️";
    case ScrollDirection.sw:
      return "↙️";
    case ScrollDirection.nw:
      return "↖️";
    case ScrollDirection.ne:
      return "↗️";
    default:
      return "_";
  }
}

function iconForPaused(paused: boolean) {
  if (paused) {
    return "⏸️";
  } else {
    return "▶️";
  }
}

const weatherOptions = [none, snow, sakura];

const aboutMeParagraphs = [
  "Hello! I'm Jaxsun, nice to meet you.",
  "I'm a software engineer from Vancouver BC with a passion for productivity software.",
  "Besides software I have a background in Physics and Linguistics. I also love hockey (both playing and watching it) and video games (only playing them, not making them).",
];

const workHistoryParagraphs = [
  "Currently I work at Asana as a Tech Lead and Software Engineer. Asana is a company making work management software for everyone, whether you run a boutique business, a Fortune 500 company, or just need a grocery list Asana can help.",
  "Before Asana I spent nearly 10 years working at Tasktop Technologies as a Software Engineer. There I worked on every product they ever launched (as well as a few that never did).",
  "And Before Tasktop I studied Computer Science and Physics at the University of British Columbia where I won an award for being a smarty-pants.",
];

const contactParagraphs = [
  "If you want to contact me you can try to find me on the internet! My unusual name makes it rather easy to find me, good luck!",
];

function Root() {
  // base state declarations
  const [month, setMonth] = React.useState(getCurrentMonth());
  const [scrollDirection, setScrollDirection] = useLocalStorage(
    "scroll-direction",
    ScrollDirection.se
  );
  const [paused, setPaused] = useLocalStorage("paused", false);
  const [weatherIndex, setWeatherIndex] = React.useState(0);
  const [buttonsDisabled, setButtonsDisabled] = React.useState(false);
  const [highlightedIndex, setHighlightedIndex] = React.useState(0);
  const [displayingIndex, setDisplayingIndex] = React.useState(0);
  const [paragraphs, setParagraphs] = React.useState(aboutMeParagraphs);

  // simple state helpers
  const nextSeason = () => setMonth((month + 3) % 12);
  const lastSeason = () => setMonth((12 + month - 3) % 12);
  const nextWeather = () => setWeatherIndex((weatherIndex + 1) % 3);
  const lastWeather = () => setWeatherIndex((3 + weatherIndex - 1) % 3);
  const season = seasonForMonth(month);

  const changeScrollDirection = () => {
    const newScrollDirection = nextScrollDirection(scrollDirection);
    setScrollDirection(newScrollDirection);
  };
  const togglePaused = () => setPaused(!paused);

  // button descriptions
  const createNavButton = (
    buttonIndex: number,
    buttonText: string,
    paragraphs: string[]
  ): NavButtonSpec => {
    const buttonId = `nav-button-${buttonIndex}`;
    const buttonSelector = `#${buttonId}`;
    return {
      buttonId,
      buttonText,
      highlighted: buttonIndex === highlightedIndex,
      displaying: buttonIndex === displayingIndex,
      onClick: () => buttonClicked(buttonIndex, paragraphs),
      onInitialClick: () => setDisplayingIndex(buttonIndex),
      setSelected: () => setButtonHighlighted(buttonSelector, buttonIndex),
    };
  };

  const actionButtons: ButtonSpec[] = [
    {
      buttonId: "action-button-1",
      buttonText: iconForScrollDirection(scrollDirection),
      onClick: changeScrollDirection,
    },
    {
      buttonId: "action-button-2",
      buttonText: iconForSeason(season),
      onClick: nextSeason,
    },
    {
      buttonId: "action-button-3",
      buttonText: iconForPaused(paused),
      onClick: togglePaused,
    },
  ];

  const navButtons: NavButtonSpec[] = [
    createNavButton(0, "About Me", aboutMeParagraphs),
    createNavButton(1, "Work History", workHistoryParagraphs),
    createNavButton(2, "Contact", contactParagraphs),
  ];

  const buttonClicked = (buttonIndex: number, paragraphs: string[]) => {
    if (!buttonsDisabled && buttonIndex !== displayingIndex) {
      onClick(
        () => setParagraphs(paragraphs),
        () => setDisplayingIndex(buttonIndex),
        setButtonsDisabled
      );
    }
  };

  const setButtonHighlighted = (
    buttonSelector: string,
    buttonIndex: number
  ) => {
    setHighlightedIndex(buttonIndex);
    animations.popOut(buttonSelector);
    // when we highlight a button we want to pop in the currently selected button if it's not the button we've just selected
    // selectedIndex will still be the oldValue since setSelectedIndex is async
    if (highlightedIndex !== buttonIndex) {
      animations.popIn(`#${navButtons[highlightedIndex].buttonId}`);
    }
  };

  const highlightPreviousNavButton = () => {
    if (highlightedIndex > 0) {
      navButtons[highlightedIndex - 1].setSelected();
    }
  };

  const highlightNextNavButton = () => {
    if (highlightedIndex < navButtons.length - 1) {
      navButtons[highlightedIndex + 1].setSelected();
    }
  };

  const selectCurrentNavButton = () => {
    navButtons[highlightedIndex].onClick();
  };

  // when we first load we want to pop out the initially highlighted button
  React.useEffect(() =>
    animations.popOut(`#${navButtons[highlightedIndex].buttonId}`)
  );

  useGamepad({
    onX: lastWeather,
    onY: nextWeather,
    onL1: lastSeason,
    onR1: nextSeason,
    onA: selectCurrentNavButton,
    onB: selectCurrentNavButton,
    onUp: highlightPreviousNavButton,
    onDown: highlightNextNavButton,
    onStart: togglePaused,
    onSelect: changeScrollDirection,
  });

  return (
    <div className="root">
      <Squares
        season={season}
        scrollDirection={scrollDirection}
        paused={paused}
      />
      <Canvas draw={weatherOptions[weatherIndex]} />
      <Content
        season={season}
        scrollDirection={scrollDirection}
        paused={paused}
        paragraphs={paragraphs}
        navButtons={navButtons}
        actionButtons={actionButtons}
        buttonsDisabled={buttonsDisabled}
      />
    </div>
  );
}

export default Root;
