import "../styles/squares.scss";

export enum Season {
  winter = "grey",
  spring = "green",
  summer = "blue",
  autumn = "brown",
}

export enum ScrollDirection {
  se = "scroll-se",
  sw = "scroll-sw",
  ne = "scroll-ne",
  nw = "scroll-nw",
}

interface Props {
  season: Season;
  scrollDirection: ScrollDirection;
  paused: boolean;
}

function Squares(props: Props) {
  const pausedClass = props.paused ? "paused" : "";
  return (
    <div
      className={`squares ${props.season} ${props.scrollDirection} ${pausedClass}`}
    ></div>
  );
}

export default Squares;
