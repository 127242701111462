import anime from "animejs";
import "../styles/button.scss";
import "../styles/nav-button.scss";

export function onClick(
  swapText: () => void,
  onInitialClick: () => void,
  setButtonsDisabled: (disabled: boolean) => void
) {
  anime
    .timeline({ autoplay: false })
    .add({
      targets: ".view",
      duration: 500,
      "max-height": 0,
      easing: "easeOutSine",
      begin: () => {
        setButtonsDisabled(true);
        onInitialClick();
      },
      complete: swapText,
    })
    .add(
      {
        targets: ".view",
        duration: 500,
        "max-height": 1000,
        easing: "easeInSine",
        complete: () => setButtonsDisabled(false),
      },
      "+=500"
    )
    .play();
}

interface Props {
  buttonId: string;
  buttonText: string;
  isDisabled: boolean;
  isSelected: boolean;
  isDisplaying: boolean;
  onClick: () => void;
  onInitialClick: () => void;
  setSelected: () => void;
}

function NavButton(props: Props) {
  const selectedStyle = props.isSelected ? "selected" : "";
  const displayingStyle = props.isDisplaying ? "displaying" : "";
  const disabledStyle = props.isDisabled ? "disabled" : "";

  return (
    <div
      className={`nav-button ${selectedStyle} ${displayingStyle} ${disabledStyle}`}
    >
      <button
        id={props.buttonId}
        disabled={props.isDisabled || props.isDisplaying}
        onMouseOver={() => props.setSelected()}
        onClick={props.onClick}
      >
        <div className="hand"></div>
        {props.buttonText}
      </button>
    </div>
  );
}

export default NavButton;
