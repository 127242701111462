import "../styles/view.scss";
import * as animations from "../ts/animations";

interface Props {
  paragraphs: string[];
}

function renderParagraph(paragraphText: string, i: number) {
  const paragraphId = `paragraph-${i}`;
  const popOut = () => animations.popOut(`#${paragraphId}`);
  const popIn = () => animations.popIn(`#${paragraphId}`);
  return (
    <p
      key={paragraphId}
      id={paragraphId}
      onMouseOver={popOut}
      onMouseLeave={popIn}
    >
      {paragraphText}
    </p>
  );
}

function View(props: Props) {
  return (
    <div className="window view">
      {props.paragraphs.map((p, i) => renderParagraph(p, i))}
    </div>
  );
}

export default View;
