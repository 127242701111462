import "../styles/content.scss";
import ButtonsPane, { ButtonSpec, NavButtonSpec } from "./ButtonsPane";
import { ScrollDirection, Season } from "./Squares";
import View from "./View";

interface Props {
  season: Season;
  scrollDirection: ScrollDirection;
  paused: boolean;
  paragraphs: string[];
  navButtons: NavButtonSpec[];
  actionButtons: ButtonSpec[];
  buttonsDisabled: boolean;
}

function Content(props: Props) {
  return (
    <div className="content">
      <ButtonsPane
        navButtons={props.navButtons}
        actionButtons={props.actionButtons}
        buttonsDisabled={props.buttonsDisabled}
      />
      <View paragraphs={props.paragraphs} />
    </div>
  );
}

export default Content;
