import "../styles/action-buttons.scss";
import "../styles/button.scss";
import { ButtonSpec } from "./ButtonsPane";

interface Props {
  buttons: ButtonSpec[];
}

function renderButton(
  buttonId: string,
  buttonText: string,
  onClick: () => void
) {
  return (
    <button key={buttonId} className="icon" onClick={onClick}>
      {buttonText}
    </button>
  );
}

function ActionButtons(props: Props) {
  return (
    <div className="window action-buttons">
      {props.buttons.map((button, i) =>
        renderButton(button.buttonId, button.buttonText, button.onClick)
      )}
    </div>
  );
}

export default ActionButtons;
