// state
const particles: Particle[] = [];
let currentMode: "none" | "snow" | "sakura" = "none";

// general parameters
const PARTICLE_COUNT = 1000;
const MAX_VX = 1;

// Snowflake parameters
const FLAKE_SIZE = 4;
const FLAKE_SCALE = 3;
const FLAKE_OPACITY_RANGE = 0.75; // (0.25-1)

// Sakura paramters
const SAKURA_SIZE = 4;
const SAKURA_SCALE = 3;
const SAKURA_OPACITY_RANGE = 0.75; // (0.25-1)

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  scale: number;
  opacity: number;
}

function setupSnowFlakes(width: number, height: number) {
  if (currentMode !== "snow") {
    currentMode = "snow";
    particles.splice(0, particles.length);
  }

  if (particles.length === 0) {
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      particles.push({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: (Math.random() - 0.5) * MAX_VX * 2,
        vy: 1 + Math.random() * 1,
        scale: 1 + Math.random() * (FLAKE_SCALE - 1),
        opacity: 1 - FLAKE_OPACITY_RANGE + Math.random() * FLAKE_OPACITY_RANGE,
      });
    }
  }
}

function setupSakura(width: number, height: number) {
  if (currentMode !== "sakura") {
    currentMode = "sakura";
    particles.splice(0, particles.length);
  }

  if (particles.length === 0) {
    for (let i = 0; i < PARTICLE_COUNT; i++) {
      particles.push({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: 0.2 + Math.random() * MAX_VX,
        vy: (1 + Math.random() * 1) / 3,
        scale: 1 + Math.random() * (SAKURA_SCALE - 1),
        opacity:
          1 - SAKURA_OPACITY_RANGE + Math.random() * SAKURA_OPACITY_RANGE,
      });
    }
  }
}

function fillBackground(
  context: CanvasRenderingContext2D,
  color: string,
  opacity: number,
  width: number,
  height: number
) {
  context.fillStyle = color;
  context.globalAlpha = opacity;
  context.fillRect(0, 0, width, height);
}

export function none(context: CanvasRenderingContext2D, frame: number) {
  // nothing on purpose
}

export function snow(context: CanvasRenderingContext2D, frame: number) {
  const { width, height } = context.canvas.getBoundingClientRect();
  setupSnowFlakes(width, height);

  fillBackground(context, "#000000", 0.3, width, height);

  context.fillStyle = "#ffffff";
  particles.forEach((particle) => {
    context.globalAlpha = particle.opacity;
    context.fillRect(
      particle.x,
      particle.y,
      particle.scale * FLAKE_SIZE,
      particle.scale * FLAKE_SIZE
    );

    particle.x = (particle.x + particle.vx * particle.scale) % width;
    particle.y = (particle.y + particle.vy * particle.scale) % height;

    particle.vx = (particle.vx + (Math.random() - 0.5) * 0.2) % MAX_VX;
  });
}

export function sakura(context: CanvasRenderingContext2D, frame: number) {
  const { width, height } = context.canvas.getBoundingClientRect();
  setupSakura(width, height);

  fillBackground(context, "#00ffff", 0.3, width, height);

  context.fillStyle = "#ffb0f1";
  particles.forEach((particle: Particle, index: number) => {
    const t = (frame + index) / 30;
    context.globalAlpha = particle.opacity;
    context.fillRect(
      particle.x + Math.sin(t) * 30,
      particle.y,
      particle.scale * SAKURA_SIZE,
      particle.scale * SAKURA_SIZE
    );

    const swingSlowdown = Math.pow(Math.cos(t), 2) * 0.5 + 0.5;

    particle.y =
      (particle.y + particle.vy * particle.scale * swingSlowdown) % height;
    particle.x = (particle.x + particle.vx * particle.scale) % width;
  });
}
